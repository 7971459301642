const WidgetSpinner = () => {
    return (
        <>
            <div id='overlaySpinner' style={{ width: "100%", height: "100%" }} className={`absolute inset-0 z-1 bg-widgetOverlay opacity-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center gap-2`}>
            </div>
            <div className={`absolute z-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center gap-2`}>
                <div className="w-5 h-5 rounded-full z-11 animate-pulse bg-primary"></div>
                <div className="w-5 h-5 rounded-full z-11 animate-pulse bg-primary"></div>
                <div className="w-5 h-5 rounded-full z-11 animate-pulse bg-primary"></div>
            </div>
        </>
    )
}

export default WidgetSpinner