import React, { ChangeEvent, SetStateAction, Dispatch, useEffect } from "react";
import { RegionsFilterResponse, divisionsFilterResponse } from "../entrypoints/localDb";
import DatePickerComponent from "./DatePicker/DatePicker";
import { FilterState, DateRange } from "../types/model";
import { fetchData } from "../services/apiService";
import WidgetSpinner from "./WidgetSpinner";

interface Props {
  clearFilters: () => void;
  filters: FilterState;
  setFiltersData: any;
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  interval: string;
  setInterval: Dispatch<SetStateAction<string>>;
  enableFilters: string[];
}

const FilterComponent: React.FC<Props> = ({ clearFilters, filters, setFiltersData, dateRange, setDateRange, interval, setInterval, enableFilters }: Props) => {

  const { data: manufactureFilterResponse, isFetching: isFetchinmanufactureFilterResponse, refetch: manufactureFilterData } = fetchData({ uniqueApiIdentifer: 'manufacturers', apiURL: `/manufacturers` });

  useEffect(() => {
    manufactureFilterData();
  }, [])

  const handleFilterChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    setFiltersData((prevFilters: FilterState) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <div className="xl:w-9/12 lg:w-9/12 xl:flex-row xl:flex xl:items-end lg:flex-row lg:flex lg:items-end md:block sm:block xsm:block 2xsm:block 3xsm:block md:w-full sm:w-full xsm:w-full 2xsm:w-full 3xsm:w-full" >
      <div className="pr-3 md:w-3/6 xsm:w-3/6 sm:w-3/6 2xsm:w-3/6 3xsm:w-3/6 md:float-left xsm:float-left sm:float-left 2xsm:float-left 3xsm:float-left">
        <button
          onClick={clearFilters}
          style={{ border: '1px solid #C4C4C8', height: '35px', marginBottom: '0px', marginTop:"24px" }}
          className="bg-white hover:bg-lightBlue font-bold py-1.5 px-1.5 rounded filterSection w-full">Clear Filter</button>
      </div>
      {enableFilters.includes("region") && (
        <div className="pr-3 md:w-3/6 xsm:w-3/6 sm:w-3/6 2xsm:w-3/6 3xsm:w-3/6 md:float-left xsm:float-left sm:float-left 2xsm:float-left 3xsm:float-left">
          <label htmlFor="region_code" className="block mb-2 text-sm-7 font-medium text-black dark:text-white">Region</label>
          <select
            name="region_code"
            value={filters?.region_code}
            onChange={handleFilterChange}
            style={{ border: '1px solid #C4C4C8', height: '35px' }}
            className="filterSection w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-indigo-600"
          >
            <option value="">All</option>
            {RegionsFilterResponse &&
              RegionsFilterResponse.map((object) => (
                <option key={object?.code} value={object?.code}>
                  {object?.name}
                </option>
              ))}
          </select>
        </div>
      )}

      {enableFilters.includes("sales_person_name") && (
        <div className="pr-3 md:w-3/6 xsm:w-3/6 sm:w-3/6 2xsm:w-3/6 3xsm:w-3/6 md:float-left xsm:float-left sm:float-left 2xsm:float-left 3xsm:float-left">
          <label htmlFor="sales_person_name" className="block mb-2 text-sm-7 font-medium text-black dark:text-white">Sales Person Name</label>
          <select
            name="sales_person_name"
            value={filters?.sales_person_name}
            onChange={handleFilterChange}
            style={{ border: '1px solid #C4C4C8', height: '35px' }}
            className="filterSection w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-indigo-600"
          >
            <option value="">All</option>
            <option value="option1">option1</option>
            <option value="option2">option2</option>
            <option value="option3">option3</option>
          </select>
        </div>
      )}

      {enableFilters.includes("division") && (
        <div className="pr-3 md:w-3/6 xsm:w-3/6 sm:w-3/6 2xsm:w-3/6 3xsm:w-3/6 md:float-left xsm:float-left sm:float-left 2xsm:float-left 3xsm:float-left md:pt-3 xsm:pt-3 sm:pt-3 2xsm:pt-3 3xsm:pt-3">
          <label htmlFor="division_code" className="block mb-2 text-sm-7 font-medium text-black dark:text-white">Division</label>
          <select
            name="division_code"
            value={filters?.division_code}
            onChange={handleFilterChange}
            style={{ border: '1px solid #C4C4C8', height: '35px' }}
            className="filterSection w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-indigo-600"
          >
            <option value="">All</option>
            {divisionsFilterResponse &&
              divisionsFilterResponse.map((object) => (
                <option key={object?.code} value={object?.id}>
                  {object?.name}
                </option>
              ))}
          </select>
        </div>
      )}

      {enableFilters.includes("revenue_type") && (
        <div className="pr-3 md:w-3/6 xsm:w-3/6 sm:w-3/6 2xsm:w-3/6 3xsm:w-3/6 md:float-left xsm:float-left sm:float-left 2xsm:float-left 3xsm:float-left md:pt-3 xsm:pt-3 sm:pt-3 2xsm:pt-3 3xsm:pt-3">
          <label htmlFor="revenue_type" className="block mb-2 text-sm-7 font-medium text-black dark:text-white">Revenue Type</label>
          <select
            name="revenue_type"
            value={filters?.revenue_type}
            onChange={handleFilterChange}
            style={{ border: '1px solid #C4C4C8', height: '35px'}}
            className="filterSection w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-indigo-600"
          >
            <option value="">All</option>
            <option value="hardware">Hardware</option>
            <option value="services">Services</option>
          </select>
        </div>
      )}

      {enableFilters.includes("manufacture") && (
        <div className="pr-3 md:w-3/6 xsm:w-3/6 sm:w-3/6 2xsm:w-3/6 3xsm:w-3/6 md:float-left xsm:float-left sm:float-left 2xsm:float-left 3xsm:float-left md:pt-3 xsm:pt-3 sm:pt-3 2xsm:pt-3 3xsm:pt-3">
          <label htmlFor="manufacture" className="block mb-2 text-sm-7 font-medium text-black dark:text-white">Manufacturer</label>
          <select
            name="manufacture"
            value={filters?.manufacture}
            onChange={handleFilterChange}
            style={{ border: '1px solid #C4C4C8', height: '35px'}}
            className="filterSection w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-indigo-600"
          >
            {isFetchinmanufactureFilterResponse && <WidgetSpinner />}
            <option value="">All</option>
            {manufactureFilterResponse &&
              manufactureFilterResponse?.data.map((object: any) => (
                <option key={object?.index} value={object?.manufacturer}>
                  {object?.manufacturer}
                </option>
              ))}
          </select>
        </div>
      )}

      {enableFilters.includes("duration") && (
        <div className={"pr-3 md:w-3/6 xsm:w-3/6 sm:w-3/6 2xsm:w-3/6 3xsm:w-3/6 md:float-left xsm:float-left sm:float-left 2xsm:float-left 3xsm:float-left pt-2"}>
          <label htmlFor="duration" className="block mb-2 text-sm-7 font-medium text-black dark:text-white">Duration</label>
          <div>
            <DatePickerComponent dateRange={dateRange} setDateRange={setDateRange} interval={interval} setInterval={setInterval} border={true} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
