import React from 'react';
import OverlaySpinner from './OverlaySpinner/OverlaySpinner';
import TableTotalFooter from './TableTotalFooter';

interface DynamicTableProps {
  tableData: { [key: string]: any }[];
  customHeaders?: string[];
  hiddenColumns?: string[] | [];
  columnRenderers?: { [key: string]: (value: any, row?: any) => React.ReactNode };
  isLoading?: boolean;
  showTableTotalFooter?: boolean;
  total_target_revenue?: number; // This is optional paramater, Only pass this value if sum is to be shown in table footer;
  total_gross_margin?: number;
  scrollBarX?:boolean
}

const TablePrimary: React.FC<DynamicTableProps> = ({ tableData, customHeaders, scrollBarX,hiddenColumns = [], columnRenderers, isLoading, showTableTotalFooter, total_target_revenue, total_gross_margin }: DynamicTableProps) => {
  const tableHeaders = customHeaders || (tableData.length > 0 ? Object.keys(tableData[0]).filter((column: string) => !(hiddenColumns as string[]).includes(column)) : []);
  const tableColumns = tableData.length > 0 ? Object.keys(tableData[0]) : [];

  const renderCellContent = (column: string, value: any, row:any): React.ReactNode => {
    if (columnRenderers && columnRenderers[column]) {
      return columnRenderers[column](value, row);
    }

    // If the value is an object, extract the nested property
    if (typeof value === 'object' && value !== null) {
      return renderNestedProperty(value);
    }
    
    return value;
  };

  const renderNestedProperty = (obj: any): React.ReactNode => {
    return obj?.name ? obj?.name : 'NA';
  };

  const isColumnHidden = (column: string): boolean => {
    return hiddenColumns !== undefined && Array.isArray(hiddenColumns) && (hiddenColumns as string[]).includes(column);
  };

  return (
    <>
      {isLoading && <OverlaySpinner />}
      <div className="rounded-sm bg-white pt-2 pb-2.5 dark:border-strokedark dark:bg-boxdark xl:pb-1 mb-1">
      <div className={`max-w-full pl-3 scrollbar-thumb-rounded-100 ${scrollBarX ? 'overflow-x-scroll' : ''}`}>
          <table className="w-full table-auto overflow-y-scroll">
            <thead>
              <tr className="text-left dark:bg-meta-4">
                {tableHeaders && tableHeaders.map((column, index) => (
                  <th key={index} className="min-w-[130px] py-2 px-2 font-medium text-grayPrimary text-left dark:text-white xl:pl-2">
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="overflow-scroll">
              {tableData && tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {(tableColumns && hiddenColumns && Array.isArray(hiddenColumns)) &&
                    tableColumns
                      .filter((column) => !isColumnHidden(column))
                      .map((column, colIndex) => (
                        <td key={colIndex} className="border-b text-black text-left border-[#eee] py-2 px-2 text-xsm dark:border-strokedark">
                          {renderCellContent(column, row[column], row)}
                        </td>
                      ))}
                </tr>
              ))}
              { tableData.length > 0 && showTableTotalFooter && total_target_revenue && total_gross_margin &&
                <TableTotalFooter tableHeaders={tableHeaders} total_target_revenue={total_target_revenue} total_gross_margin={total_gross_margin}/>
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TablePrimary;
