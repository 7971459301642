import { useEffect, useState } from 'react';
import OpenOrdersLogo from '../../../assets/images/icons/orders_active.svg'
import FilterComponent from '../../components/FilterComponent';
import LineChart from '../../components/LineChart';
import SearchField from '../../components/SearchField';
import TablePrimary from '../../components/TablePrimary';
import VerticalBarChart from '../../components/VerticalBarChart';
import { OpenOrdersDivision, OpenOrdersLineChartOptions, OpenOrdersRegion, defaultFilterState } from '../../entrypoints/localDb';
import { DateRange, FilterState } from '../../types/model';
import { fetchData } from '../../services/apiService';
import { humanReadableValue, getSeriesOpenordersTotalByRegion, getSeriesOpenordersProjectRevenue, getSeriesOpenordersTotalByDivision } from "../../common/utils/helperFunctions";
import WidgetSpinner from "../../components/WidgetSpinner";

const OpenOrders = () => {

  const dateRangeYearToDate = {
    "startDate": "",
    "endDate": ""
  };
  const [dateRange, setDateRange] = useState<DateRange>(dateRangeYearToDate);
  const [interval, setInterval] = useState<string>('quarter');
  const [searchKey, setSearchKey] = useState("");
  const [filters, setFilters] = useState<FilterState>(defaultFilterState);
  const [customerQuery, setCustomerQuery] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [selectTitle, setselectTitle] = useState("");
  const [selectCustomer, setselectCustomer] = useState("");

  const { data: openordersTotal, isFetching: openordersTotalisFetching, refetch: openordersTotalRefetch } = fetchData({ uniqueApiIdentifer: 'open_orders', apiURL: `/open_orders?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&region_code=${filters?.region_code}&division_id=${filters?.division_code}&manufacture=${filters.manufacture}&interval=${interval}&customer_id=${customerQuery}` });
  const { data: openordersTotalByRegion, isFetching: openordersTotalisFetchingByRegion, refetch: openordersTotalRefetchByRegion } = fetchData({ uniqueApiIdentifer: 'open_orders_by_region', apiURL: `/open_orders/orders_by_region?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&region_code=${filters?.region_code}&division_id=${filters?.division_code}&manufacture=${filters.manufacture}&interval=${interval}&customer_id=${customerQuery}` });
  const { data: openordersTotalByDivision, isFetching: openordersTotalFetchingByDivision, refetch: openordersTotalRefetchByDivision } = fetchData({ uniqueApiIdentifer: 'open_orders_by_division', apiURL: `/open_orders/orders_by_division?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&region_code=${filters?.region_code}&division_id=${filters?.division_code}&manufacture=${filters.manufacture}&interval=${interval}&customer_id=${customerQuery}` });
  const { data: openordersTotalByRevenues, isFetching: openordersTotalFetchingByRevenues, refetch: openordersTotalRefetchByRevenues } = fetchData({ uniqueApiIdentifer: 'open_orders_by_Revenues', apiURL: `/open_orders/projected_revenues?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&region_code=${filters?.region_code}&division_id=${filters?.division_code}&manufacture=${filters.manufacture}&interval=${interval}&customer_id=${customerQuery}` });
  const { data: openordersTotalByCustomer, isFetching: openordersTotalFetchingByCustomer, refetch: openordersTotalRefetchByCustomer } = fetchData({ uniqueApiIdentifer: `open_orders_by_Customer_${searchKey}`, apiURL: `/open_orders/orders_by_customer?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&region_code=${filters?.region_code}&division_id=${filters?.division_code}&manufacture=${filters.manufacture}&interval=${interval}&customer_query=${searchKey}` });

  const { xAxisCategories: xAxis_openOrdersByRegion, series: series_openOrdersByRegion } = getSeriesOpenordersTotalByRegion(openordersTotalByRegion?.region && openordersTotalByRegion?.region || []);
  const { xAxisCategories: xAxis_openOrdersByDivision, series: series_openOrdersByDivision } = getSeriesOpenordersTotalByDivision(openordersTotalByDivision?.division && openordersTotalByDivision?.division || []);
  const { xAxisCategories: xAxis_openOrdersByRevenues, series: series_openOrdersByRevenues } = getSeriesOpenordersProjectRevenue(openordersTotalByRevenues && openordersTotalByRevenues?.projected_revenue || []);

  useEffect(() => {
    // if (dateRange.startDate && dateRange.endDate) {
      openordersTotalRefetch();
      openordersTotalRefetchByRegion();
      openordersTotalRefetchByDivision();
      openordersTotalRefetchByRevenues();
      openordersTotalRefetchByCustomer();
    // }

    if (interval == "month" && (dateRange.startDate && dateRange.endDate)) {
      setselectTitle("Current Quarter")
    } else if (interval == "quarter" && (dateRange.startDate && dateRange.endDate)) {
      setselectTitle("Current Year")
    } else if (interval == "week" && (dateRange.startDate && dateRange.endDate)) {
      setselectTitle("Current Month")
    } else (
      setselectTitle("")
    )

  }, [dateRange, filters, searchKey, interval, selectTitle])

  const OpenOrdersCardData = {
    title: 'Total Open order',
    value: '$0.03bn',
    icon: 'OpenOrders'
  }

  const handleClick = (row: any) => {
    setCustomerQuery(row?.id);
    setCustomerName(row?.name);
  }

  useEffect(()=>{
    openordersTotalRefetch();
    openordersTotalRefetchByRegion();
    openordersTotalRefetchByDivision();
    openordersTotalRefetchByRevenues();

    if (customerName !== "") {
      setselectCustomer(customerName)
    }
  },[customerQuery, selectCustomer])

  const customHeaders: string[] = ['Customer', 'Sum of Line Value']

  const columnRenderers = {
    name: (value: any, row: any) => <div className="text-darkBlue cursor-pointer" onClick={() => handleClick(row)}>{value}</div>,
    revenue: (value: any, row: any) => <div className="text-darkBlue text-base">
    {`${humanReadableValue(value)} (${humanReadableValue(row?.percentage)}%)`}
  </div>,
  };

  const clearFilters = () => {
    setDateRange(dateRangeYearToDate);
    setInterval('quarter');
    setFilters(defaultFilterState);
    setCustomerQuery("");
    setCustomerName("");
    setselectTitle("");
    setselectCustomer("");
  }

  return (
    <>
      <div className='flex flex-col xl:flex-row justify-between mb-2 pr-2 pb-2'>
        <h2 className="xl:text-title-md2 lg:text-title-md2 md:text-comman-heading sm:text-comman-heading xsm:text-comman-heading 2xsm:text-comman-heading 3xsm:text-comman-heading font-semibold text-primary dark:text-white mb-3 xl:ml-2 lg:ml:2 xl:mt-6 lg:mt-2 md:mt-2 2xsm:mt-2 sm:mt-2">
          Open Orders {selectCustomer ? ` -- ${selectCustomer} ` : ''}{selectTitle ? `By ${selectTitle}` : ""}
        </h2>
        <FilterComponent clearFilters={clearFilters} filters={filters} setFiltersData={setFilters} dateRange={dateRange} setDateRange={setDateRange} interval={interval} setInterval={setInterval} enableFilters={["region", "division","manufacture", "duration"]} />
      </div>
      <div className="flex rounded-lg flex-col sm:flex-col xl:grid xl:grid-cols-8 grid-rows-4 gap-4 p-1 w-full">
        <div className="rounded-md overflow-hidden col-span-2 p-1">
          <div id='promaryCardContainer' className="flex bg-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex-row content-center p-5 box-border rounded-lg" style={{ height: "6.5rem" }}>
            <div className="flex h-15.5 w-15.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
              <img src={OpenOrdersLogo} alt={`${OpenOrdersCardData.title} icon`} />
            </div>

            <div className="relative items-center ml-5">
              <div>
                <span style={{ fontWeight: 600 }} className="text-comman-heading pb-2.5 font-sm text-darkBlue">{OpenOrdersCardData.title}</span>
                <div className="pb-1.5 text-lg font-bold text-primary ">{humanReadableValue(openordersTotal?.total_open_orders)}</div>
              </div>
              {openordersTotalisFetching && <WidgetSpinner />}
            </div>
          </div>
        </div>

        <div className="bg-white relative rounded-lg shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-scrollColor scrollbar-h-1 scrollbar-w-1 scrollbar-thumb-rounded-full scrollbar-track-gray p-4 row-span-4 col-span-2 col-start-1 row-start-2" style={{ height: '435px' }}>
          <SearchField searchKey={searchKey} setSearchKey={setSearchKey} />
          {openordersTotalByCustomer?.customers && <TablePrimary tableData={openordersTotalByCustomer?.customers} customHeaders={customHeaders} columnRenderers={columnRenderers} hiddenColumns={['id', 'percentage']} scrollBarX={false} />}
          {openordersTotalFetchingByCustomer && <WidgetSpinner />}
        </div>

        <div className="relative rounded-lg overflow-hidden row-span-2 col-span-3 col-start-3 row-start-1">
          <VerticalBarChart chartType={'bar'} title={'Region'} options={{ ...OpenOrdersRegion, series: series_openOrdersByRegion, xaxis: { ...OpenOrdersRegion?.xaxis, categories: xAxis_openOrdersByRegion } }} chartHeight={230} chartBorderDissable={true} />
          {openordersTotalisFetchingByRegion && <WidgetSpinner />}
        </div>

        <div className="relative rounded-lg overflow-hidden row-span-2 col-start-6 col-span-3 row-start-1">
          <VerticalBarChart chartType={'bar'} title={'Division'} options={{ ...OpenOrdersDivision, series: series_openOrdersByDivision, xaxis: { ...OpenOrdersDivision?.xaxis, categories: xAxis_openOrdersByDivision } }} chartHeight={230} chartBorderDissable={true} />
          {openordersTotalFetchingByDivision && <WidgetSpinner />}
        </div>

        <div className="relative rounded-lg overflow-hidden row-span-3 col-start-3 col-span-6 row-start-3">
          <LineChart title={'Projected Revenue'} options={{ ...OpenOrdersLineChartOptions, series: series_openOrdersByRevenues, xaxis: { ...OpenOrdersLineChartOptions?.xaxis, categories: xAxis_openOrdersByRevenues } }} chartHeight={255} />
          {openordersTotalFetchingByRevenues && <WidgetSpinner />}
        </div>
      </div>
    </>

  )
}

export default OpenOrders;
