import { lazy } from 'react';


const Dashboard = lazy(() => import('../pages/Dashboard/index.tsx'));
const PageNotFound = lazy(() => import('../pages/PageNotFound/index.tsx'));
const TransactionDetails = lazy(() => import('../pages/TransactionDetails/index.tsx'));
const OpenOrders = lazy(() => import('../pages/OpenOrders/index.tsx'));
const GlobalRevenue = lazy(() => import('../pages/GlobalRevenue/index.tsx'));
const AccountTypeBreakout = lazy(() => import('../pages/AccountTypeBreakout/index.tsx'));
const Bookings = lazy(() => import('../pages/Bookings/index.tsx'));
const RevenueByCustomer = lazy(() => import('../pages/RevenueByCustomer/index.tsx'));
//Please replace PageNotFound component with respeactive component.

const coreRoutes = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/global-revenue',
    title: 'Dashboard',
    component: GlobalRevenue,
  },
  {
    path: '/account-type-breakout',
    title: 'Dashboard',
    component: AccountTypeBreakout,
  },
  {
    path: '/transaction-details',
    title: 'Dashboard',
    component: TransactionDetails,
  },
  {
    path: '/open-orders',
    title: 'Dashboard',
    component: OpenOrders,
  },
  {
    path: '/bookings',
    title: 'Dashboard',
    component: Bookings,
  },
  {
    path: '/revenue-by-customer',
    title: 'Dashboard',
    component: RevenueByCustomer,
  },
  {
    path: '*',
    title: 'Page Not Found',
    component: PageNotFound,
  }
];

const routes = [...coreRoutes];
export default routes;
