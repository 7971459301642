import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

interface VerticalBarChartProps {
  title?: string,
  chartHeight: number,
  options: ApexOptions,
  chartType: 'bar' | 'line',
  chartBorderDissable?: boolean
}

const VerticalBarChart = ({ options, chartType, chartHeight, chartBorderDissable }: VerticalBarChartProps) => {

  return (
    <div className={`col-span-12 row-span-1 rounded-lg bg-white px-4 pb-4 pt-4 ${chartBorderDissable ? 'border border-stroke shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] dark:border-strokedark' : ''} xl:col-span-3 pb-0`}>
      {chartType == 'line' && <ReactApexChart
        options={options}
        series={options.series}
        type={'line'}
        height={chartHeight}
      />}
      {chartType == 'bar' && <ReactApexChart
        options={options}
        series={options.series}
        type={'bar'}
        height={chartHeight}
      />}
    </div>
  );
};

export default VerticalBarChart;
