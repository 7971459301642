// api.js

import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

declare const process: {
  env: {
    REACT_APP_API_URL: string;
    REACT_APP_ENV: string;
    // Add other environment variables as needed
  };
};

const baseURL = import.meta.env.VITE_REACT_APP_API_URL as string;

interface FethProps {
  uniqueApiIdentifer: string,
  apiURL: string
}

// const baseURL = 'https://spyglass-staging.denaliai.com/api/v1';

const cookies = document.cookie.split(';').reduce((acc: Record<string, string>, cookie) => {
  const [key, value] = cookie.trim().split('=') as [string, string];
  acc[key] = value;
  return acc;
}, {});


// uniqueApiIdentifer is any unique string to identify different endpoints and help caching.
// staleTime is to specify how long the data remains valid in the cache.
export const fetchData = ({ uniqueApiIdentifer, apiURL }: FethProps) => {

  const navigate = useNavigate();

  return useQuery(uniqueApiIdentifer, async () => {
    const response = await fetch(`${baseURL}${apiURL}`, {
      headers: {
        'apiKey': `${cookies?.spyglass_access_token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    // Check for "Invalid token" error
    if (data.error === "Invalid token") {
      // Perform logout logic
      fetch('/logout_azure', { method: 'get' })
      .then(() => navigate('/login'));
    }

    return data;
  },
    {
      staleTime: 90000 // Set stale time to 90 seconds (adjust as needed)
    }
  );
};

export const postData = (data: {}) => {
  return useMutation(async () => {
    const response = await fetch('/api/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return response.json();
  });
};