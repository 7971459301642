import Logo from '../../../assets/images/logo/logo.png';
import { Link } from 'react-router-dom';

const LoginPage = () => {
    return (
        <div className="bg-gray-100">
            <div className="min-h-screen flex items-center justify-center">
                <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
                    <div className="flex justify-center mb-8">
                        <img src={Logo} alt="Logo" className="w-30 h-20" />
                    </div>
                    <h1 className="text-2xl font-semibold text-center text-gray-500 mt-8 mb-6">Spyglass</h1>
                    <form action="/login_azure" method="get">
                        <h1 className='text-primary text-xl'>LOGIN</h1>
                        <p>Login to access your account</p>
                        <br />
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm text-gray-600">Email Address</label>
                            <input type="email" id="email" name="email" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500" required />
                        </div>
                        <button type="submit" className="w-32 bg-darkBlue from-cyan-400 to-cyan-600 text-white py-2 rounded-lg mx-auto block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 mt-4 mb-6">Continue</button>
                    </form>
                    <div className="text-center">
                        <p className="text-base"><Link to="/forgot-password" className="text-cyan-600">Forgot Password?</Link></p>
                    </div>
                    <p className="text-xs text-darkBlue text-center mt-10">FOR TECHNICAL SUPPORT CONTACT US</p>
                </div>
            </div>
        </div>
    )
}

export default LoginPage