import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Loader from '../common/Loader';
import routes from '../routes';
import OpenOrders from '../pages/OpenOrders/index.tsx';
import LoginPage from '../pages/Login/index.tsx';

const DefaultLayout = lazy(() => import('../layout/DefaultLayout'));

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    // Redirect to '/open-orders' when the component mounts
    if(pathname === '/'){
      navigate('/open-orders', { replace: true });
    }
  }, [pathname]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/open-orders" replace={true} />} />
        <Route element={<DefaultLayout />}>
          <Route index element={<OpenOrders />} />
          {routes.map((routes, index) => {
            const { path, component: Component } = routes;
            return (
              <Route
                key={index}
                path={path}
                element={
                  <Suspense fallback={<Loader />}>
                    <Component />
                  </Suspense>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </>
  );
}

export default App;
