import React, { ChangeEvent } from "react"

interface SearchProps {
  searchKey: string,
  setSearchKey: React.Dispatch<React.SetStateAction<string>>,
}

const SearchField = ({searchKey, setSearchKey}:SearchProps) => {

  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    setSearchKey(event?.target?.value);
  }
  return (
    
<div className="w-65 mb-3">   
    <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray sr-only dark:text-white">Search</label>
    <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input aria-placeholder="Search by customer name" placeholder="Search by customer name" onChange={handleSearch} value={searchKey} type="text" id="default-search" className="block w-full p-2.5 ps-10 bg-gray text-sm text-ehite rounded-3xl dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white hover:border-transparent focus:outline-none" required/>
    </div>
</div>
  )
}

export default SearchField