import './OverlaySpinner.scss'

const OverlaySpinner = () => {
    return (
        <>
            <div id="overlay">
                <div className="spinner"></div>
                <br />
                Loading...
            </div>
        </>
    )
}

export default OverlaySpinner