import { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import "../../../assets/stylesheets/entrypoints/datepicker.css";
import { DateRange } from "../../types/model";
import { getCurrentMonthDates, getCurrentQuarterDates, getCurrentYearDates } from "../../common/utils/helperFunctions"
import { useLocation } from 'react-router-dom';

const DatePickerComponent = ({ border, dateRange, setDateRange, interval, setInterval }: { border?: boolean; dateRange: DateRange; setDateRange: React.Dispatch<React.SetStateAction<DateRange>>, interval: string, setInterval: React.Dispatch<React.SetStateAction<string>> }) => {
  const [value, setValue] = useState(dateRange);

  const handleValueChange = (newValue: any, shortcuts: any) => {
    setValue(newValue);
    setDateRange(newValue);
    const selectedShortcut = Object.keys(shortcuts).find(key => {
      return shortcuts[key].period.start === newValue.startDate && shortcuts[key].period.end === newValue.endDate;
    });

    if (selectedShortcut === "CurrentYear") {
      setInterval("quarter")
    }
    if (selectedShortcut === "CurrentQuarter") {
      setInterval("month")
    }
    if (selectedShortcut === "CurrentMonth") {
      setInterval("week")
    }
    if (selectedShortcut === "last3Years") {
      setInterval("year")
    }
    if (selectedShortcut === "openOrdersAllTime") {
      setInterval("quarter");
      setDateRange({
        "startDate": "",
        "endDate": ""
      });
      setValue({
        "startDate": "",
        "endDate": ""
      });
    }

  };

  const todayDate = new Date().toISOString().split("T")[0];
  let location: any = useLocation();

  const pathToDisplayContentOpenOrderPage = '/open-orders';
  const pathToDisplayGlobalRevenuePage = '/global-revenue';
  const pathToDisplayContentAccountPage = '/account-type-breakout';
  const pathToDisplayContentBookingsPage = '/bookings';

  useEffect(() => {
    // Function to append a ul with five li items to the element with the specified class
    const appendSelectToElement = () => {
      const element = document.querySelector(
        ".md\\:border-b.mb-3.lg\\:mb-0.lg\\:border-r.lg\\:border-b-0.border-gray-300.dark\\:border-gray-700.pr-1"
      );

      if (element) {
        const handleSelectChange: EventListener = (event: any) => {
          const value = event?.target?.value;
          if (value && typeof value === 'string') {
            setInterval(event.target.value.toLowerCase())
          }
        };
        const select = document.createElement("select");
        select.id = "selectRange";
        select.style.minWidth = "11rem";
        select.style.borderRadius = "5px";
        select.style.margin = "5px";
        select.style.padding = "5px";
        select.style.backgroundColor = "#add8e6";
        select.style.height = "33px";
        select.style.outline = "none";
        select.style.display = "inline";
        select.addEventListener("change", handleSelectChange as EventListener); // Add event listener

        const options = ["Month", "Week", "Day", "Quarter", "Year"];

        for (let i = 0; i < options.length; i++) {
          const option = document.createElement("option");
          option.value = options[i];
          option.textContent = options[i];

          // Set interval as default selected value
          if (options[i] === interval) {
            option.selected = true;
          }

          select.appendChild(option);
        }
        element.insertBefore(select, element.firstChild);
      }
    };

    // Call the function after the component is mounted
    appendSelectToElement();

    const appendYTDButtonToElement = (onClickHandler: any) => {
      const element = document.querySelector(
        ".md\\:border-b.mb-3.lg\\:mb-0.lg\\:border-r.lg\\:border-b-0.border-gray-300.dark\\:border-gray-700.pr-1"
      );

      if (element) {
        const button = document.createElement("button");
        button.style.minWidth = "11rem";
        button.style.borderRadius = "5px";
        button.style.margin = "5px";
        button.style.padding = "10px";
        button.style.backgroundColor = "#add8e6";
        button.style.height = "33px";
        button.style.outline = "none";
        button.style.display = "inline";
        button.style.textAlign = "left";
        button.textContent = "YTD";
        button.addEventListener("click", () => {
          onClickHandler("quarter");
        });

        element.appendChild(button);
      }
    };
    const appendQTDButtonToElement = (onClickHandler: any) => {
      const element = document.querySelector(
        ".md\\:border-b.mb-3.lg\\:mb-0.lg\\:border-r.lg\\:border-b-0.border-gray-300.dark\\:border-gray-700.pr-1"
      );

      if (element) {
        const button = document.createElement("button");
        button.style.minWidth = "11rem";
        button.style.borderRadius = "5px";
        button.style.margin = "5px";
        button.style.padding = "10px";
        button.style.backgroundColor = "#add8e6";
        button.style.height = "33px";
        button.style.outline = "none";
        button.style.display = "inline";
        button.style.textAlign = "left";
        button.textContent = "QTD";
        button.addEventListener("click", () => {
          onClickHandler("month");
        });

        element.appendChild(button);
      }
    };

    const appendMTDButtonToElement = (onClickHandler: any) => {
      const element = document.querySelector(
        ".md\\:border-b.mb-3.lg\\:mb-0.lg\\:border-r.lg\\:border-b-0.border-gray-300.dark\\:border-gray-700.pr-1"
      );

      if (element) {
        const button = document.createElement("button");
        button.style.minWidth = "11rem";
        button.style.borderRadius = "5px";
        button.style.margin = "5px";
        button.style.padding = "10px";
        button.style.backgroundColor = "#add8e6";
        button.style.height = "33px";
        button.style.outline = "none";
        button.style.display = "inline";
        button.style.textAlign = "left";
        button.textContent = "MTD";
        button.addEventListener("click", () => {
          onClickHandler("week");
        });

        element.appendChild(button);
      }
    };

    // const className = ".my-element-class";
    const onClickHandler = (interval: any) => {
      setInterval(interval)
    };

    if (location.pathname === pathToDisplayGlobalRevenuePage ||
      location.pathname === pathToDisplayContentAccountPage ||
      location.pathname === pathToDisplayContentBookingsPage) {
      appendYTDButtonToElement(onClickHandler);
      appendQTDButtonToElement(onClickHandler);
      appendMTDButtonToElement(onClickHandler);
    }

  }, []);

  useEffect(() => {
    setValue(dateRange);
  }, [dateRange])

  const currentMonthDates = getCurrentMonthDates();
  const currentQuarteDates = getCurrentQuarterDates();
  const currentYearDates = getCurrentYearDates();

  // Calculate the start date for the last3Years dynamically
  const threeYearsAgo = new Date();
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
  const last3YearsStart = threeYearsAgo.toISOString().split("T")[0];


  const shortcuts = {
    ...(location.pathname === pathToDisplayContentOpenOrderPage
      ? {
        openOrdersAllTime: {
            text: "All Time",
            period: {
              start: last3YearsStart,
              end: todayDate,
            },
        },
        CurrentYear: {
          text: "Current Year",
          period: {
            start: currentYearDates?.startDate,
            end: currentYearDates?.endDate,
          },
        },
        CurrentQuarter: {
          text: "Current Quarter",
          period: {
            start: currentQuarteDates?.startDate,
            end: currentQuarteDates?.endDate,
          },
        },
        CurrentMonth: {
          text: "Current Month",
          period: {
            start: currentMonthDates?.startDate,
            end: currentMonthDates?.endDate,
          },
        },
      }
      : {last3Years: {
            text: "All Time",
            period: {
              start: last3YearsStart,
              end: todayDate,
            },
          }}),
  };

  return (
    <div id="datePicketContainer" style={{ border: border ? "2px solid #C4C4C8" : "", borderRadius: "10px" }}>
      <Datepicker
        value={value}
        maxDate={new Date(todayDate)}
        configs={{
          shortcuts: shortcuts
        }}
        showFooter={true}
        onChange={(newValue) => handleValueChange(newValue, shortcuts)}
        showShortcuts={true}
      />
    </div>
  );
};

export default DatePickerComponent;
