import { humanReadableValue } from "../common/utils/helperFunctions";

interface RevenueTableProps {
  tableHeaders: any;
  total_target_revenue: number,
  total_gross_margin: number
}


const TableTotalFooter = ({ tableHeaders, total_target_revenue, total_gross_margin }: RevenueTableProps) => {
  const getFooterData = (column: string) => {
    switch (column) {
      case "Global Customer ":
        return "Total"

      case "Sum of Revenue":
        return `${total_target_revenue ? humanReadableValue(total_target_revenue) : "-"}`;

      case "Sum of GM":
        return `${total_gross_margin ? humanReadableValue(total_gross_margin): "-"}`;

      default:
        return "-";
    }
  }
  return (
    <tr className="position-sticky">
      {(tableHeaders && tableHeaders.map((column: string) => {
        const content = getFooterData(column);
        return (
          <td key={tableHeaders} className="border-b  border-[#eee] py-2 px-0 text-xsm dark:border-strokedark">
            <div className={`bg-graySecondary ${content == '-' ? 'text-graySecondary' : 'text-black'} text-title-sm font-semibold text-left  py-1 px-2.2 pl-2`}>
              {content}
            </div>
          </td>
        )
      }))}
      <br />
    </tr>
  );
}

export default TableTotalFooter;

