import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

interface LineChartProps {
  title?:string,
  options: ApexOptions,
  chartHeight: number
}

const LineChart = ({options, chartHeight}:LineChartProps) => {

  return (
    <div className="col-span-12 row-span-1 border border-stroke bg-white p-3 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] dark:border-strokedark dark:bg-boxdark xl:col-span-3 rounded-lg pb-0">
          <ReactApexChart
            options={options}
            series={options.series}
            type="line"
            height={chartHeight}
          />
    </div>
  );
};

export default LineChart;
